import React, { useState, Fragment } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const keyMap = {
  memberName: "會員名稱\n(廠名)",
  owner: "負責人\n姓名",
  homeAddress: "通訊處\n地址",
  homeTel: "通訊處\n電話",
  homeFax: "通訊處\n傳真",
  factoryAddress: "工廠\n地址",
  factoryTel: "工廠\n電話",
  factoryFax: "工廠\n傳真",
  factoryDetail: "工廠\n設備",
  products: "經營\n產品",
  representative: "會員\n代表",
  unionTitle: "公會\n職務",
  companyTitle: "公司\n職務",
  // birthday: "生日",
  // diploma: "學歷",
  liaisonOfficeAddress: "聯絡處\n地址",
  liaisonOfficeTel: "聯絡處\n電話",
  liaisonOfficeFax: "聯絡處\n傳真",
  affiliateWith: "關係\n企業",
  joinedAt: "入會\n日期",
}

const emptyAssocaitor = Object.keys(keyMap).reduce((obj, key) => {
  obj[key] = ""
  return obj
}, {})

const regionOrder = {
  基隆市: 1,
  台北市: 2,
  新北市: 3,
  桃園市: 4,
  新竹市: 5,
  新竹縣: 6,
  苗栗縣: 7,
  台中市: 8,
  彰化縣: 9,
  南投縣: 10,
  雲林縣: 11,
  嘉義市: 12,
  嘉義縣: 13,
  台南市: 14,
  高雄市: 15,
  屏東縣: 16,
  宜蘭縣: 17,
  花蓮縣: 18,
  台東縣: 19,
  澎湖縣: 20,
  金門縣: 21,
  連江縣: 22,
}

const regions = Object.keys(regionOrder)

const Associator = ({ data }) => {
  const [region, setRegion] = useState("新北市")
  const regionToCount = {}
  let associators = []
  data.allStrapiAssociator.edges.forEach(({ node }) => {
    associators.push(node)
    if (node.region in regionToCount) regionToCount[node.region] += 1
    else regionToCount[node.region] = 1
  })

  associators = associators.filter(a => a.region === region)
  if (associators.length % 2) associators.push(emptyAssocaitor)
  const pairs = []
  for (let i = 0; i < associators.length; i += 2) {
    pairs.push([associators[i], associators[i + 1]])
  }

  return (
    <Layout>
      <SEO title="Associator" />
      <div className="associator-list__counter">{`會員總數: ${Object.values(
        regionToCount
      ).reduce((accu, curr) => accu + curr, 0)}名`}</div>
      <div className="associator-list">
        <div className="associator-list__tabs">
          {regions
            .filter(r => r in regionToCount)
            .map((r, i) => (
              <div
                key={`region-${i}`}
                className={`associator-list__tab ${
                  region === r ? "associator-list__tab--active" : ""
                }`}
                onClick={() => setRegion(r)}
              >
                {r}
                <div className="associator-list__tab-count">
                  {regionToCount[r]}
                </div>
              </div>
            ))}
        </div>
        <Fragment>
          {pairs.map((row, i) => (
            <table className="associator-list__table" key={`table-${i}`}>
              <tbody>
                {Object.keys(keyMap).map((key, j) => (
                  <tr key={`row-${j}`}>
                    <td>
                      <pre>{keyMap[key]}</pre>
                    </td>
                    <td>{row[0][key]}</td>
                    <td>{row[1][key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </Fragment>
      </div>
    </Layout>
  )
}

export default Associator

export const query = graphql`
  query {
    allStrapiAssociator {
      edges {
        node {
          id
          affiliateWith
          birthday
          companyTitle
          diploma
          factoryAddress
          factoryDetail
          factoryFax
          factoryTel
          homeAddress
          homeFax
          homeTel
          joinedAt
          liaisonOfficeAddress
          liaisonOfficeFax
          liaisonOfficeTel
          memberName
          owner
          products
          region
          representative
          unionTitle
        }
      }
    }
  }
`
